<script lang="ts" setup>
const steps = [
  'Strategy',
  'Project Details',
  'Submit for Review',
]
</script>

<template>
  <div>
    <NuxtLayout name="dashboard">
      <template #header>
        <div class="flex gap-4 items-center w-full text-xl font-semibold text-forgd-primary-900">
          <div class="flex space-x-4 items-center">
            <NuxtLink to="/listing-and-liquidity" class="flex items-center">
              <UIcon name="i-heroicons-arrow-left" class="w-5 h-5" />
            </NuxtLink>
            <div>Listing & Liquidity configurator</div>
          </div>
          <div class="ml-[200px] translate-y-2">
            <UiSteps :steps="steps" :index="$route.meta.step " />
          </div>
        </div>
      </template>
      <slot />
    </NuxtLayout>
  </div>
</template>
